
import Hero from './Hero'

const Home = ({movies}) => {
  return (

    <Hero movies={movies}/>

  )
}

export default Home
